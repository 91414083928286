export const environment = {
  production: true,
  publicConfigUrl: '/api/public/configuration',
  localDevSsoRedirect: false,
  ssoLoginRedirectIntervalInHours: 24,

  sentry: {
    DSN: 'https://b7327f10c293fa5595df102da868e640@o4504165036457984.ingest.us.sentry.io/4506998761062400',
    environment: 'stage',
    tracePropagationTargets: ["localhost", /^\//],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  }
};
